:root {
  --bs-link-color: #e56a33;
  --bs-link-hover-color: #e56a33;
  --bs-body-font-family: 'Montserrat';
  --bs-body-font-size: 1em;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #333;
  --bs-primary: #e56a33;
  --bs-secondary: #6c757d;
  --bs-success: #469d73;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
  --bs-primary-rgb: 229,106,51;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 70,157,115;
  --bs-info-rgb: 23,162,184;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 52,58,64;
  --bs-border-color: #dee2e6;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-special-hover: rgba(229,106,51,0.2);
  --bs-main-bg-light: #E1E1E1;
  --bs-main-bg-dark: #b3b2b2;
}

ion-content {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.btn {
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: transparent;
  --bs-btn-font-size: 1em;
  --bs-btn-bg: transparent;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e56a33;
  --bs-btn-border-color: #e56a33;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d66330;
  --bs-btn-hover-border-color: #e56a33;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #d66330;
  --bs-btn-active-border-color: #e56a33;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e56a33;
  --bs-btn-disabled-border-color: #e56a33;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #d66330!important;
  border-color: #e56a33!important;
}

.btn-primary {
  color: #fff;
  background-color: #e56a33;
  border-color: #e56a33;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5a6268;
  --bs-btn-hover-border-color: #57585a;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a6268;
  --bs-btn-active-border-color: #57585a;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #57585a;
  --bs-btn-disabled-border-color: #57585a;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268!important;
  border-color: #5a6268 !important;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.rounded-pill {
  border-radius: 5rem;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(87,87,86,0.9);
  --bs-btn-border-color: #575756;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #575756;
  --bs-btn-hover-border-color: #575756;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(87,87,86,0.9);
  --bs-btn-active-border-color: #929292;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #929292;
  --bs-btn-disabled-border-color: #929292;
}

.form-check-input:checked {
  background-color: #57585a;
  border-color: #57585a;
}

.btn-outline-primary {
  --bs-btn-color: #e56a33;
  --bs-btn-border-color: #e56a33;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e56a33;
  --bs-btn-hover-border-color: #ff4094;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e56a33;
  --bs-btn-active-border-color: #e56a33;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #e56a33;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e56a33;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #57585a;
  --bs-btn-border-color: #57585a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #57585a;
  --bs-btn-hover-border-color: #57585a;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #57585a;
  --bs-btn-active-border-color: #57585a;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #57585a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #57585a;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #575756;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #575756;
  --bs-btn-hover-border-color: #575756;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #575756;
  --bs-btn-active-border-color: #575756;
  --bs-btn-active-shadow: inset 0 3px 5px transparent;
  --bs-btn-disabled-color: #929292;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #929292;
  --bs-gradient: none;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-link-hover-border-color: #eaecf4 #eaecf4 #dddfeb;
  --bs-nav-tabs-link-active-color: #fff;
  --bs-nav-tabs-link-active-bg: var(--bs-primary);
  --bs-nav-tabs-link-active-border-color: #dddfeb #dddfeb #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.form-select:focus {
  border-color: #c7c6c6;
  box-shadow: none;
}

.form-control {
  color: #444;
  border: 1px solid #ced4da;
  border-radius: 12px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: 3px 3px 5px 0px #80808036;
}

.form-control:focus {
  border-color: #c7c6c6;
  box-shadow: none;
}

.link-primary:focus, .link-primary:hover {
  color: #e56a33!important;
}

.link-primary {
  color: #e56a33!important;
}

.card {
  --bs-card-spacer-y: 0.5rem;
  --bs-card-cap-bg: var(--bs-secondary);
  --bs-card-cap-color: #fff;
}

.text-bg-primary {
  color: #fff!important;
  background-color: #e56a33!important;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-btn-color: #ffffff;
  --bs-accordion-btn-bg: #e56a33;
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-focus-border-color: #e56a33;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: #e56a33;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29%234668c9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.bg-primary {
  background-color: #e56a33 !important;
}

.bg-grey {
  background-color: var(--bs-gray-200);
}

