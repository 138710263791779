body, ion-content {
  font-family: var(--bs-body-font-family)!important;
}

@media (max-width: 575px) {
  body, ion-content {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  body, ion-content {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body, ion-content {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  body, ion-content {
    font-size: 16px;
  }
}

.btn, a, .form-control, .form-select {
  font-size: inherit;
}

.btn:focus, .btn:active {
  box-shadow: none;
}

input[type="file"] {
  background-color: var(--bs-secondary);
  color: #ffffff;
  width: 100%;
  max-width: 400px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--bs-body-font-family);
  font-weight: 400 !important;
}

@media (max-width: 575px) {
  .h6, h6 {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .h6, h6 {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h6, h6 {
    font-size: 15px;
  }
}

@media (min-width:992px) {
  .h6, h6 {
    font-size: 16px;
  }
}

@media (max-width: 575px) {
  .h5, h5 {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .h5, h5 {
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h5, h5 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .h5, h5 {
    font-size: 21px;
  }
}

@media (max-width: 575px) {
  .h4, h4 {
    font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .h4, h4 {
    font-size: 22px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h4, h4 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .h4, h4 {
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  .h3, h3 {
    font-size: 24px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .h3, h3 {
    font-size: 26px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h3, h3 {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .h3, h3 {
    font-size: 30px;
  }
}

@media (max-width:575px) {
  .h2, h2 {
    font-size: 29px;
  }
}

@media (min-width: 576px) and (max-width:767px) {
  .h2, h2 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h2, h2 {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .h2, h2 {
    font-size: 34px;
  }
}

@media (max-width:575px) {
  .h1, h1 {
    font-size: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .h1, h1 {
    font-size: 34px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .h1, h1 {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .h1, h1 {
    font-size: 42px;
  }
}

.btn:focus, .btn:active {
  box-shadow: none;
}

.table td, .table thead th {
  vertical-align: middle;
}

.tab-design .tab-pane {
  margin-top: 16px;
}

.tab-design .nav-item {
  text-transform: uppercase;
  font-weight: 600;
}

.tab-design .nav-link.active {
  color: #fff;
  border-color: #878787;
  background-color: #57585a;
}

.tab-design .nav-link:hover {
  border-color: #878787;
}

.tab-design .nav-link {
  padding: 4px 30px;
  font-size: 80%;
  border-color: #e3e6f0;
  background-color: #fff;
}

.flex-sep-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header.masthead {
  color: #fff;
  text-align: center;
}

@media (max-width: 991px) {
  header.masthead .title1 {
    font-size: 4rem;
    font-family: "Anton";
  }
}

@media (min-width: 992px) {
  header.masthead .title1 {
    font-size: 5rem;
    font-family: "Anton";
  }
}

@media (max-width: 991px) {
  header.masthead .title2 {
    font-size: 2.5rem;
    font-family: "Anton";
  }
}

@media (min-width: 992px) {
  header.masthead .title2 {
    font-size: 3rem;
    font-family: "Anton";
  }
}

header.masthead {
  background: linear-gradient(0deg, var(--bs-black) 0%, #e56a33 100%);
}

.subhead {
  font-weight: 300!important;
  line-height: 1.2;
  font-family: "Anton";
  text-transform: capitalize;
}

.w-900 {
  max-width: 900px;
  margin: auto;
}

.w-900x {
  max-width: 900px;
}

@media (min-width: 992px) {
  .box-white {
    padding: 40px 32px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .box-white {
    padding: 28px 24px;
  }
}

@media (max-width: 575px) {
  .box-white {
    padding: 24px 22px;
  }
}

.box-white {
  background: #fff;
  box-shadow: 0.5px 0.5px 3px #f4804d;
  border-radius: 20px;
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    /*max-width: 1040px;*/
  }
}

.fs-small {
  font-size: 90%;
}

.fs-big {
  font-size: 120%;
}

@media (max-width: 575px) {
  .text-padding {
    padding: 28px 24px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .text-padding {
    padding: 34px 30px;
  }
}

@media (min-width: 992px) {
  .text-padding {
    padding: 52px 48px;
  }
}

.diagonal-box-up {
  transform: skewY(-2deg);
}

.diagonal-box-up.bordered {
  border: .2rem solid;
  padding: 26px 24px 10px;
}

.diagonal-box-content-up {
  transform: skewY(+2deg);
}

.diagonal-box-down {
  transform: skewY(+2deg);
}

.diagonal-box-content-down {
  transform: skewY(-2deg);
}

.anton {
  font-family: "Anton";
  font-weight: 200!important;
}

.service-box {
  display: flex;
  padding: 18px 12px;
}

.bg-primary-fade-diag {
  background: linear-gradient(130deg, var(--bs-white) 0%, #e56a33 100%);
}

.bg-primary-fade {
  background: linear-gradient(0deg, var(--bs-white) 0%, #e56a33 100%);
}

.bg-grey-fade-diag {
  background: linear-gradient(130deg, var(--bs-white) 35%, var(--bs-gray-200) 50%);
}

.full-screen {
  height: calc(100vh - 46px);
  align-content: center;
}

.page-title {
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .page-title {
    padding-top: calc(2rem + 72px);
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .page-title {
    padding-top: calc(2rem + 55px);
    padding-bottom: 2rem;
  }
}

@media (max-width: 575px) {
  .text-padding-sm {
    padding: 24px 16px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .text-padding-sm {
    padding: 30px 28px;
  }
}

@media (min-width: 992px) {
  .text-padding-sm {
    padding: 34px 32px;
  }
}

ul.spaced li {
  margin-bottom: 1.5rem;
}

.team-img {
  width: 100%;
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}

