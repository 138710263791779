.navbar {
  --bs-navbar-brand-font-size: 1.2em;
  --bs-navbar-padding-y: 0.1rem;
  --bs-nav-link-color: var(--bs-nav-link-color);
  --bs-navbar-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-hover-color: var(--bs-link-hover-color);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgb(173,81,40);
}

.navbar {
  background-color: var(--bs-light);
  border-bottom: 1px solid var(--bs-secondary);
}

@media (min-width: 1200px) {
  .navbar-logo {
    width: 100px;
  }
}

@media (max-width:1199px) {
  .navbar-logo {
    width: 90px;
  }
}

.navbar .nav-item {
  text-transform: capitalize;
}

.footer {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  padding: 16px 16px 8px 16px;
}

.footer .copyright {
  color: #fff;
  opacity: 0.80;
  margin-bottom: 0;
  font-size: 80%;
  width: 100%;
}

.footer .version {
  color: #fff;
  opacity: 0.50;
  margin-bottom: 0;
  font-size: 80%;
  padding: 0 16px 0 0;
}

